// CSS

import "normalize.css";

import "./team-up/css/main.css";
import "reflect-metadata";
import "es6-shim";

// JS
window.$ = window.jQuery = require("jquery");
window.Popper = require("popper.js").default;
require("./team-up/js/main");

if(IS_ADMIN){
	const boostrapMaterialDesignCss = import("bootstrap-material-design/dist/css/bootstrap-material-design.min.css");
}
