/********************************/
/*********** TEAM UP ************/
/********************************/

function replaceAllSansCasse(machaine, chaineARemaplacer, chaineDeRemplacement) {
    return machaine.replace(new RegExp(chaineARemaplacer, "gi"), chaineDeRemplacement);
}

function dump(obj) {
    var out = "";
    for (var i in obj) {
        out += i + ": " + obj[i] + "\n";
    }

    // or, if you wanted to avoid alerts...
    var pre = document.createElement("pre");
    pre.innerHTML = out;
    document.body.appendChild(pre);
}

var providers = [
    {
        service: "youtube",
        regex: /(?:youtube\.(com|fr)|youtu\.be)(?:\/watch\?v=|\/)(.+)/,
        iframe: '<iframe class="resp-iframe" src="https://www.youtube.com/embed/{id}?rel=0" frameborder="0" allowfullscreen > </iframe>   '
    },
    {
        service: "vimeo",
        regex: /(?:vimeo\.com\/)(?:channels\/[A-z]+\/)?([0-9]+)/,
        iframe:
            '<iframe src="https://player.vimeo.com/video/{id}?color=ffffff&title=0&byline=0&portrait=0" class="resp-iframe" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
    },
    {
        service: "dailymotion",
        regex: /(?:dailymotion\.com\/|dai\.ly)(?:video|hub)?\/([0-9a-z]+)/,
        iframe: '<iframe frameborder="0" class="resp-iframe" src="//www.dailymotion.com/embed/video/{id}" allowfullscreen></iframe>'
    }
    /*
    ,{
        service: 'image',
        regex: /^((?:https?:\/\/)?.+jpg|png|gif)$/,
        iframe: '<img src="{id}" style="display: block;" />'
    }
    */
];

String.prototype.sansAccent = function() {
    var accent = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g // C, c
    ];
    var noaccent = ["A", "a", "E", "e", "I", "i", "O", "o", "U", "u", "N", "n", "C", "c"];

    var str = this;
    for (var i = 0; i < accent.length; i++) {
        str = str.replace(accent[i], noaccent[i]);
    }

    return str;
};

function is_url_video(str) {
    var bIsUrlVideo;

    var matches = [],
        id,
        service,
        iframe,
        regex;

    bIsUrlVideo = false;

    for (i = 0; i < providers.length; i++) {
        regex = new RegExp(providers[i].regex);
        if ((matches = str.match(regex))) {
            id = matches[1];
            /*
            service = providers[i].service;
			iframe = providers[i].iframe.replace( /{id}/, id );
			$( this ).replaceWith( '<span style="display: block; font-size: 80%">' + service + '</span>' + iframe );
            */
            bIsUrlVideo = true;
        }
    }

    return bIsUrlVideo;
}

function is_url(str) {
    regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
        return true;
    } else {
        return false;
    }
}

$(document).ready(function() {
    // changement de canal quand plusieurs canaux possible
    // gestion du choix du canal dans le menu
    $(".radioChoixIdRole").click(function() {
        /* changement du filtre sur le container */
        var id_role = $(this).val();
        var url_location = "index.php?rub=8&p=45679&id_role=" + id_role;
        document.location.href = url_location;
    });

    // affichage du message de changement de canal
    // $("#myModalConnexion").modal("show");

    // setTimeout(function() {
    //     $("#myModalConnexion").modal("hide");
    // }, 3000);

    // compteur de notification
    function load_unseen_notification() {
        //alert(aj);

        $.ajax({
            url: "ajx.php",
            async: true,
            cache: false,
            method: "POST",
            data: { aj: 2 },
            dataType: "json",
            success: function(data) {
                //alert(data.unseen_notification);
                //$('.dropdown-menu').html(data.notification);

                if (data.unseen_notification > 0) {
                    //alert(data.unseen_notification);
                    $(".count").show();
                    //                $('.aucune-notification').hide();
                    $(".count").html(data.unseen_notification);
                } else {
                    $(".count").hide();
                    //$('.aucune-notification').show();
                }
            },
            error: function() {
                //alert("failure");
            }
        });
    }

    // load_unseen_notification();

    // setInterval(function() {
    //     load_unseen_notification();
    // }, 5000);

    // canal de publication d'un post
    $(".modalCanalOption").click(function() {
        /* changement du filtre sur le container */
        var id_canal = $(this).val();
        //alert(id_canal);

        var libelleCanal = $(this).data("libelle");

        //$('#id_type_canal').val(id_canal);
        $("#typeCanal").html(libelleCanal);
        $("#modalCanal").modal("hide");
    });

    // se positionne sur l'input de l'url lors de l'ouverture de ma modal
    $("#ajouterUrlModal").on("shown.bs.modal", function() {
        $("#inputUrl").focus();
    });

    // lors de la création d'un post
    // ajout d'une url
    // validation
    $("#ajouterUrlModal #inputUrl").change(function() {
        var myUrl = $(this).val();
        myUrl = myUrl.toLowerCase();
        myUrl = myUrl.sansAccent();
        var bIsUrl = is_url(myUrl);
        if (bIsUrl == true) {
            $("#ajouterUrlModal .pictoInput").addClass("pictoOK");
            $("#ajouterUrlModal .pictoInput").removeClass("pictoWarning");
            $("#ajouterUrlModal .ajouter").show();
        } else {
            $("#ajouterUrlModal .pictoInput").removeClass("pictoOK");
            $("#ajouterUrlModal .pictoInput").addClass("pictoWarning");

            $("#ajouterUrlModal .ajouter").hide();
        }

        //alert( "Handler for .change() called." );
    });

    $("#ajouterUrlModal .ajouter").click(function() {
        var urlAjout = $("#inputUrl").val();
        /**
         * A cause du type="url" chrome veut absolument que l'url commence par http://
         * On vide le champ afin qu'il ne le valide pas
         */
        $("#inputUrl").val("");
        //urlAjout = urlAjout.toLowerCase();
        urlAjout = urlAjout.sansAccent();
        urlAjout = replaceAllSansCasse(urlAjout, "www", "www");
        $(".container-url-add").addClass("has-url");
        $(".container-url-add .monUrl").html(urlAjout);
        $(".container-url-add #lien_url ").val(urlAjout);
        $(".container-input-post .action-bar #url").hide();
        $("#ajouterUrlModal").modal("hide");
    });

    $(".container-url-add .deleteUrl").click(function() {
        $(".container-url-add").removeClass("has-url");
        $(".container-url-add .monUrl").html();
        $(".container-url-add #lien_url ").val();
        $(".container-input-post .action-bar #url").show();
        //$("#ajouterUrlModal").modal('hide');
    });

    // se positionne sur l'input de la vidéo lors de l'ouverture de ma modal
    $("#ajouterVideoModal").on("shown.bs.modal", function() {
        $("#inputVideo").focus();
    });

    // vérifie si l'url de la vidéo est correcte
    // youtube , dailymotion, vimeo

    $("#ajouterVideoModal #inputVideo").change(function() {
        var myUrl = $(this).val();
        myUrl = myUrl.toLowerCase();
        myUrl = myUrl.sansAccent();

        var bIsUrl = is_url_video(myUrl);
        if (bIsUrl == true) {
            $("#ajouterVideoModal .pictoInput").addClass("pictoOK");
            $("#ajouterVideoModal .pictoInput").removeClass("pictoWarning");
            $("#ajouterVideoModal .ajouter").show();
        } else {
            $("#ajouterVideoModal .pictoInput").removeClass("pictoOK");
            $("#ajouterVideoModal .pictoInput").addClass("pictoWarning");

            $("#ajouterVideoModal .ajouter").hide();
        }

        //alert( "Handler for .change() called." );
    });

    // sauvegarde le lien vidéo et ferme la modal

    $("#ajouterVideoModal .ajouter").click(function() {
        var urlAjout = $("#inputVideo").val();
        //urlAjout = urlAjout.toLowerCase();
        /**
         * A cause du type="url" chrome veut absolument que l'url commence par http://
         * On vide le champ afin qu'il ne le valide pas
         */
        $("#inputVideo").val("");

        urlAjout = urlAjout.sansAccent();

        urlAjout = replaceAllSansCasse(urlAjout, "www", "www");
        urlAjout = replaceAllSansCasse(urlAjout, "youtube", "youtube");
        urlAjout = replaceAllSansCasse(urlAjout, "dailymotion", "dailymotion");
        urlAjout = replaceAllSansCasse(urlAjout, "vimeo", "vimeo");

        $(".container-video-add").addClass("has-video");
        //$(".container-video-add .monUrl").html(urlAjout);
        $(".container-video-add #lien_video ").val(urlAjout);

        var matches = [],
            id,
            service,
            iframe,
            regex;

        for (i = 0; i < providers.length; i++) {
            regex = new RegExp(providers[i].regex);
            if ((matches = urlAjout.match(regex))) {
                //dump(matches);
                id = matches[2];
                var vars = {};
                id.replace(location.hash, "").replace(
                    /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
                    function(m, key, value) {
                        // callback
                        vars[key] = value !== undefined ? value : "";
                        alert(key);
                    }
                );
                if (typeof vars["v"] != "undefined") {
                    id = vars["v"];
                }
                //dump(vars);
                //alert(id);

                service = providers[i].service;

                iframe = providers[i].iframe.replace(/{id}/, id);

                $(".container-video-add .monUrl").replaceWith("" + iframe);
            }
        }
        $(".container-input-post .action-bar #photo").hide();
        $(".container-input-post .action-bar #video").hide();

        $("#ajouterVideoModal").modal("hide");
    });

    $(".container-video-add .deleteVideo").click(function() {
        $(".container-video-add").removeClass("has-video");
        $(".container-video-add .monUrl").html("");
        $(".container-video-add #lien_video ").val("");
        $(".container-input-post .action-bar #photo").show();
        $(".container-input-post .action-bar #video").show();
        //$("#ajouterUrlModal").modal('hide');
    });

    function readURL(input) {
        if (input.files && input.files[0]) {
            var file = input.files[0];
            var fileType = file["type"];
            //alert(input.files[0].size);
            var ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];
            if ($.inArray(fileType, ValidImageTypes) < 0) {
                // invalid file type code goes here.
            } else {
                var reader = new FileReader();

                reader.onload = function(e) {
                    //alert(e.target.result);
                    $(".container-image-add .uploadPhoto").css("background-image", "url(" + e.target.result + ")");
                    //$('#blah').attr('src', e.target.result);
                    $(".container-image-add").addClass("has-photo");
                    /*
                    var widthElement4 = $('.container-image-add .onePhotoElement').width();
                    $('.container-image-add .onePhotoElement').css('height',widthElement4);
                    */
                };

                reader.readAsDataURL(input.files[0]);
                $(".container-input-post .action-bar #video").hide();
                $(".container-input-post .action-bar #photo").hide();
            }
        }
    }

    // à l'ajout d'une image dans parcourir
    // on va l'afficher en prévisualisation
    $(".container-input-post .action-bar #file").change(function() {
        readURL(this);
    });

    // supprime le container avec l'image
    // on vide l'input file
    $(".container-image-add .deleteImage").click(function() {
        $(".container-image-add").removeClass("has-photo");
        $(".container-image-add .uploadPhoto").css("background-image", "none");

        //$("#ajouterUrlModal").modal('hide');
        $(".container-input-post .action-bar #file").val("");
        $(".container-input-post .action-bar #video").show();
        $(".container-input-post .action-bar #photo").show();
    });

    /* gestion menu like */
    // par défaut

    var tmp = "filter-general";
    $("#containerLikes").removeClass("filter-like filter-mdr filter-general filter-etonne filter-inquiet filter-applaudir");
    $("#containerLikes").addClass(tmp);
    //alert(tmp);
});
